import React from 'react'


class VariousTypes extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }



  render() {


    return (
        <section className="choosedesire ychoose various pt-100 mb-0">

          <div className="container">
                <h2 className="heading-h2 text-center"><span className="heading-h3"><span className="bluecolor">Various Types</span> of</span>Cryptocurrency Exchange Script For your Business</h2>
                <p className="pharagraph head text-center">Choose your desired type of cryptocurrency exchange script and start your dream exchange business.</p>
            <div className="d-lg-flex firstblck">
              <div className="col pt-md-3" >
                <div className="innerBlock" >
                  <div className="icn-left">
                    <div className="icn-img">
                      <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/otc/whitelabel-platform.svg" alt="orders-book-cryptocurrency-exchange-image1" />
                    </div>
                  </div>
                  <div className="content-rght">
                    <h3>
                    Orders Book Crypto trading platform like Binance
                    </h3>
                    <p className="pharagraph">
                    Our bug-free Binance clone script comes with the best order book feature. Our script helps cryptocurrency startups and entrepreneurs to launch a stunning crypto trading platform with a powerful order book system.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col pt-md-3" >
                <div className="innerBlock" >
                  <div className="icn-left">
                    <div className="icn-img">
                      <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/home/p2p-ads-based-cryptocurrency-exchange.png" alt="p2p-ads-based-cryptocurrency-exchange-image1" />
                    </div>
                  </div>
                  <div className="content-rght">
                    <h3>
                    P2P Ads Based Cryptocurrency Exchange Script like LocalBitcoins
                    </h3>
                    <p className="pharagraph" >
                    Our premium peer-to-peer LocalBitcoins clone script will help young startups and entrepreneurs to build a superfine p2p ads-based cryptocurrency trading platform as per the business needs.
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-flex lastblck">
              <div className="col pt-md-3" >
                <div className="innerBlock" >
                  <div className="icn-left">
                    <div className="icn-img">
                      <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/home/user-to-admin-otc-crypto-exchange.png" alt="user-to-admin-otc-crypto-exchange-image1" />
                    </div>
                  </div>
                  <div className="content-rght">
                    <h3>
                    User to admin/OTC Cryptocurrency Exchange Script Like Coinbase
                    </h3>
                    <p className="pharagraph" >
                    We offer the first-class Coinbase clone script with ultimate trading features and tightened security mechanisms. Our OTC trading script will help you to create and deploy your own OTC/user-admin platform similar to Coinbase.
                    </p>
                    
                  </div>
                </div>
              </div>
              <div className="col pt-md-3" >
                <div className="innerBlock" >
                  <div className="icn-left">
                    <div className="icn-img">
                      <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/home/decentralized-dex-exchange.png" alt="decentralized-dex-exchange-image1" />
                    </div>
                  </div>
                  <div className="content-rght">
                    <h3>
                    Decentralized DeFi Exchange Script Like Pancakeswap
                    </h3>
                    <p className="pharagraph" >
                    Our Decentralized Pancakeswap clone software includes all the important swapping modules and robust security features. Our error-free pancakeswap clone helps to create and deploy a secure DeFi-based DEX platform similar to pancakeswap instantly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
  }
}

export default VariousTypes