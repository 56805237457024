import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react'


class CoreFeatures extends Component {


  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
            <h3 className="heading-h2 text-center"><span className="heading-h3"><span className="bluecolor">Core Features</span> of our</span>
              Cryptocurrency Exchange Script</h3>
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  User Dashboard
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Admin Dashboard
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Different Order Types
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Crypto and Fiat Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Advanced Chart Tools
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  KYC/AML
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Support System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Admin Profit Management
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  API Documentation
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Multi-lingual
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab11')} >
                  Referral System
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab12')} >
                  Margin Trading
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab13')} >
                  Futures Trading
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab14')} >
                  IEO Launchpad
                </li>
              </ul>
              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">User Dashboard</h4>
                        <p className="pharagraph">The user dashboard comes with all paramount modules to assist your users in trading, depositing, and withdrawing the funds without any hassle.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home-new/user-dashboard.png"
                        alt="User Dashboard image1"
                        width={438}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Admin Dashboard</h4>
                        <p className="pharagraph">Our admin dashboard comes with an eye-catching user-friendly interface. You can control all the functions and security modules by using the admin panel. As the owner of the crypto trading platform, you can easily make the desired changes and manage them effectively. Besides, you can view the complete information of user transactions history, user balance, completed trade, daily transactions, withdrawal history, wallet transactions, and more.</p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home-new/admin-dashboard.png"
                        alt="Admin Dashboard image1 "
                        width={438}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Different Order Types
                        </h4>
                        <p className="pharagraph">We integrate Limit, Market and Stop order types in our software which helps your users to trade cryptocurrency as per their preference based on the price movement.</p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home/different-order-types.png"
                        alt="Different Order Types image1"
                        width={487}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Crypto and Fiat Support</h4>
                        <p className="pharagraph">Our script comes with multi-currency support. So your users can utilize both fiat and cryptocurrencies. The scalable architecture in our premier cryptocurrency trading script helps to enable any currency in the future if you need it.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home/crypto-and-fiat-support.png"
                        alt="Crypto and Fiat Support image1"
                        width={472}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Advanced Chart Tools</h4>
                        <p className="pharagraph">We provide a cutting-edge crypto trading graphical chart feature in our script that helps your users to view orders, positions, cryptocurrency price, etc. Therefore, your users can get the best graphical trading experience with the latest chart functionalities.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home-new/advanced-char-tools.png"
                        alt="Advanced Chart Tools image1"
                        width={438}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">KYC/AML</h4>
                        <p className="pharagraph">We enable KYC/AML feature where your users have to submit their identity documents for verification. You can analyze those documents and give permission for trading cryptos. This helps you to filter the anonymous and unwanted users.</p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home-new/kyc-verify.png"
                        alt="KYC/AML image1"
                        width={317}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Support System</h4>
                        <p className="pharagraph">Instant support system which helps your users to raise queries and also a chat box for them to directly chat with the admin.</p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home/support-system.png"
                        alt="Support System image1"
                        width={438}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Admin Profit Management</h4>
                        <p className="pharagraph">Our software comes with a transparent profit management system for Admin where the commissions would be calculated and displayed.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home/admin-profit-management.png"
                        alt="Admin Profit Management image1"
                        width={438}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">API Documentation
                        </h4>
                        <p className="pharagraph"> We provide a clear API documentation that helps other platforms to connect with your exchange.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home-new/api-documentation.webp"
                        alt="API Documentation image1"
                        width={532}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multi-lingual</h4>
                        <p className="pharagraph">Multilingual is one of the core features of our software. You can build a first-class cryptocurrency exchange with multi-language support. By using this feature, you can increase the user count, and also you can grab the attention of crypto users globally.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home/multi-lingual.png"
                        alt="Multi-lingual image1"
                        width={487}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab11" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4"> Referral System</h4>
                        <p className="pharagraph"> By using this feature, your users can refer to another person and earn cash rewards instantly. So we integrated this core feature to help you in increasing the user count quickly and attract many users easily.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home-new/referral-system.png"
                        alt="Referral System image1"
                        width={487}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab12" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Margin Trading</h4>
                        <p className="pharagraph">Traders can use borrowed funds to lever up a trade which is a major benefit in margin trading. Your users can open a larger position with a smaller amount of funds by using this feature.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home-new/margin-trading.png"
                        alt="Margin Trading image1"
                        width={438}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab13" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Futures Trading</h4>
                        <p className="pharagraph">When it comes to Futures trading, the buyer or seller should complete their orders at the agreed price and the days. If the price of the underlying asset is moved to the fixed price within the contracted days, then profit or loss can be determined based on that.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home-new/futures-trading.png"
                        alt="Futures Trading image1"
                        width={438}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab14" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">IEO Launchpad</h4>
                        <p className="pharagraph">IEO is the latest crypto crowdfunding model and a must-have feature in the cryptocurrency trading platform. We enable this outstanding feature in our script which helps your users to list their token for sale in your launchpad.
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/home/ieo-launchpad.png"
                        alt="IEO Launchpad image1"
                        width={438}
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures