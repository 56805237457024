import React from 'react'




class QuickviewSection extends React.Component {

  render() {
    return (
      <section className="quick pt-100 mb-0">
        <div className="container text-center">
            <h2 className="heading-h2 text-center"><span className="heading-h3"><span className="bluecolor">Quick View</span> of our </span>Cryptocurrency Exchange Script</h2>
            <video className="lazyload text-center" width="1137" height="622" controls preload={true}  muted={true} playsinline >
              <source src="https://coinsclone.mo.cloudinary.net/images/home/landingvideos.mp4?cld-content-marker=jit" type="video/mp4"></source>
            </video>
        </div>
      </section>
    )
  }
}

export default QuickviewSection